export const getImgPath = (imgName, prefix) => {
  if (!prefix) {
    prefix = import.meta.env.MODE === 'production' ? '/build' : '../../frontend'
  }

  return new URL(prefix + imgName, import.meta.url).href
}

export const getCurrency = (val = 0) => '$' + val.toFixed(2)

export const fileListFrom = attachments => {
  const files = attachments?.map(attachment => attachment.file)
  const b = new ClipboardEvent('').clipboardData || new DataTransfer()

  for (const file of files) b.items.add(file)

  return b.files
}

export async function hashEmail(email = '') {
  const data = new TextEncoder().encode(email.toLowerCase())
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  return Array.from(new Uint8Array(hashBuffer))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('')
}
